<template>
  <div>
    <Top></Top>
    <div class="web_box">
      <div class="title">忘记密码</div>
    </div>
    <div class="web_content">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="items_box">
          <span class="xing">*</span>
          <el-form-item label="手机号" prop="phone">
            
            <el-input
              v-model="ruleForm.phone"
              autocomplete="off"
              placeholder="请输入手机号"
              @change="checkPhoneNumber"
            ></el-input>
            <div class="error" v-show="phoneVisable">{{ phoneMessage }}</div>
          </el-form-item>
        </div>
    <div class="items_box">
      <span class="xing1">*</span>
          <el-form-item label="短信验证码" prop="code">
          
          <div class="text" @click="showPictureCode" v-show="show">
            获取验证码
          </div>
          <div class="text" v-show="!show">再次获取({{ count }}s)</div>
          <el-input
            v-model="ruleForm.smsCode"
            autocomplete="off"
            placeholder="请输入验证码"
            @change="checkCodeNumber"
          ></el-input>
          <div class="error" v-show="codeVisable">{{ codeMessage }}</div>
        </el-form-item>
    </div>

      <div class="items_box">
         <span class="xing2">*</span>
          <el-form-item label=" 密码" prop="password">
          <el-input
            v-model="ruleForm.password"
            autocomplete="off"
            placeholder="请输入8-16位数字、字母、特殊字符的组合"
            @change="checkPassword"
            show-password
          ></el-input>
          <div class="error" v-show="passwordVisable">
            {{ passwordMessage }}
          </div>
        </el-form-item>
      </div>

      <div class="items_box">
        <span class="xing3">*</span>
          <el-form-item label=" 确认密码" prop="confirm_password">
          <el-input
            v-model="ruleForm.passwordAgain"
            autocomplete="off"
            placeholder="请输入确认密码"
            @change="checkPasswordAgain"
            show-password
          ></el-input>
          <div class="error" v-show="passwordAgainVisable">
            {{ passwordAgainMessage }}
          </div>
        </el-form-item>
      </div>
      </el-form>
    </div>
    <div class="btnn">
      <div class="btn" @click="submitForm">确定</div>
      <div class="fan">
        <span @click="back" style="cursor: pointer">返回首页</span>
      </div>
    </div>
    <PuzzleCode
      :bind="$attrs"
      :show="showVcode"
      success-text="验证成功"
      fail-text="验证失败"
      slider-text="拖动滑块完成拼图"
      @success="success"
      @close="close"
    />
  </div>
</template>
<script>
import Banner from "@/components/banner/banner.vue";
import Top from "../../components/top/index.vue";
import PuzzleCode from "@/components/PuzzleCode";

export function isvalidPhone(str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  return reg.test(str);
}
export function isvalidPassword(str) {
  const reg =
    /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)^.{8,16}$/;
  return reg.test(str);
}

export default {
  components: {
    Banner,
    Top,
    PuzzleCode,
  },
  data() {
    return {
      //验证码倒计时
      show: true,
      count: "",
      timer: null,

      showVcode: false,

      ruleForm: {
        phone: "", //手机号
        code: "", //验证码
        password: "", //密码
        confirm_password: "", //确认密码
      },

      phoneVisable: false,
      codeVisable: false,
      passwordVisable: false,
      passwordAgainVisable: false,

      phoneMessage: "",
      codeMessage: "",
      passwordMessage: "",
      passwordAgainMessage: "",

      phoneCheck: false,
      codeCheck: false,
      passwordCheck: false,
      passwordAgainCheck: false,
    };
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    //滑块验证码
    showPictureCode() {
      this.checkPhoneNumber();
      if (this.phoneCheck === true) {
        this.showVcode = true;
      }
    },
    success() {
      this.showVcode = false;
      this.getCode();
    },
    close() {
      this.showVcode = false;
    },
    //生成验证码
    getCode(formName) {
      this.$http({
        method: "get",
        url: "/code/sms",
        params: {
          mobile: this.ruleForm.phone,
          type: "13"
        },
      })
        .then((res) => {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //校验手机号
    checkPhoneNumber() {
      var value = this.ruleForm.phone;
      if (!value) {
        this.phoneVisable = true;
        this.phoneMessage = "请输入手机号码";
        this.phoneCheck = false;
      } else if (!isvalidPhone(value)) {
        this.phoneVisable = true;
        this.phoneMessage = "手机号码格式不正确";
        this.phoneCheck = false;
      } else {
        this.$http({
          method: "post",
          url: "/home/checkPhone",
          params: {
            phone: this.ruleForm.phone
          },
        }).then((res) => {
            if (res.errorCode == "200") {
              this.phoneVisable = false;
              this.phoneMessage = "";
              this.phoneCheck = true;
            }else{
              this.phoneVisable = true;
              this.phoneMessage = "无效手机号";
              this.phoneCheck = false;
            }
        }).catch((res) => {
          console.log(res);
        });
        
      }
      
    },
    //校验验证码
    checkCodeNumber() {
      var value = this.ruleForm.smsCode;
      if (!value) {
        this.codeVisable = true;
        this.codeMessage = "请输入验证码";
        this.codeCheck = false;
      } else {
        this.$http({
          method: "post",
          url: "/code/valid",
          params: {
            smsCode: this.ruleForm.smsCode,
            mobile: this.ruleForm.phone,
          },
        })
          .then((res) => {
            console.log(res.data);
            if (res.data === null) {
              this.codeVisable = false;
              this.codeMessage = "";
              this.codeCheck = true;
            } else {
              this.codeVisable = true;
              this.codeMessage = res.data;
              this.codeCheck = false;
            }
          })
          .catch((res) => {
            console.log(res);
          });
      }
    },
    //校验密码
    checkPassword() {
      var value = this.ruleForm.password;
      if (!value) {
        this.passwordVisable = true;
        this.passwordMessage = "请输入密码";
        this.passwordCheck = false;
      } else if (!isvalidPassword(value)) {
        this.passwordVisable = true;
        this.passwordMessage = "请输入8-16位数字、字母、特殊字符的组合";
        this.passwordCheck = false;
      } else {
        this.passwordVisable = false;
        this.passwordMessage = "";
        this.passwordCheck = true;
      }
    },
    //校验确认密码
    checkPasswordAgain() {
      var value = this.ruleForm.passwordAgain;
      if (!value) {
        this.passwordAgainVisable = true;
        this.passwordAgainMessage = "请输入确认密码";
        this.passwordAgainCheck = false;
      } else if (this.ruleForm.password !== value) {
        this.passwordAgainVisable = true;
        this.passwordAgainMessage = "两次密码输入不一致";
        this.passwordAgainCheck = false;
      } else {
        this.passwordAgainVisable = false;
        this.passwordAgainMessage = "";
        this.passwordAgainCheck = true;
      }
    },

    //提交表单
    submitForm() {
      this.checkPhoneNumber();
      this.checkCodeNumber();
      this.checkPassword();
      this.checkPasswordAgain();
      if (
        this.phoneCheck &&
        this.codeCheck &&
        this.passwordCheck &&
        this.passwordAgainCheck
      ) {
        this.$http({
          method: "post",
          url: "/home/forgetPassword",
          params: {
            phone: this.ruleForm.phone,
            password: this.ruleForm.password,
            
          },
        })
          .then((res) => {
            console.log(res.errorCode,"忘记密码")
            if (res.errorCode == "200") {
              this.$message({
                type: 'success',
                showClose: true,
                message: "修改成功",
              });
              this.show = !show;
              clearInterval(this.timer);
              this.$router.push('/')
            }else{
              this.$message({
                type: 'error',
                showClose: true,
                message: "无效手机号",
              });
            }
            })
          .catch((res) => {
            console.log(res);
          });
      } else {
        console.log("error submit!!");
        return false;
      }
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.title {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  text-align: center;
  margin-top: 47px;
  margin-bottom: 50px;
}
.text {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #3275d6;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 99;
  cursor: pointer;
}
.web_content {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
}
::v-deep .el-input__inner {
  width: 404px;
  height: 48px;
  border-radius: 24px;
}
::v-deep .el-form-item {
  /* margin-top: 24px; */
  /* display: flex; */
  height: 70px;
  /* position: relative; */
}
.items_box{
  position: relative;
}

.xing {
 position: absolute;
 left: 6%;
 top: 20%;
 color: #F56C6C;
}
.xing1 {
 position: absolute;
 left: -1%;
 top: 20%;
 color: #F56C6C;
}
.xing2 {
 position: absolute;
 left: 9%;
 top: 20%;
 color: #F56C6C;
}
.xing3 {
 position: absolute;
 left: 3%;
 top: 20%;
 color: #F56C6C;
}
::v-deep .el-form-item:nth-child(1) {
  margin-top: 0px;
}
::v-deep .el-form-item__label {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
  /* width: 120px !important; */
}
::v-deep .el-input {
  font-size: 16px;
}
.btnn {
  width: 504px;
  margin: auto;
}
.btn {
  width: 404px;
  height: 48px;
  background: #3275d6;
  border-radius: 24px;
  margin-left: 100px;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  cursor: pointer;
  margin-top: 50px;
}
.fan {
  text-align: right;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #3275d6;
  margin-top: 30px;
}
.fan span {
  border-bottom: 1px solid #3275d6;
}

.error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
.text {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #3275d6;
  position: absolute;
  top: 5px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
}
</style>