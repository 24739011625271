<template>
<div class="login_top">
   <div class="logintop_min">
          <div class="min_top">
            <div class="top_img1">
              <img src="../../assets/logo.png" alt="" />
            </div>
            <div class="top_img2">
              <img src="../../assets/平台名称.png" alt="" />
            </div>
          </div>
        </div>
</div>
  
</template>
<script>

export default {
  name:'banner',
  data(){
    return{
     
    }
  },
  mounted() {
  


  },
  methods: {
   
  },
  computed: {
  
  }

};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.login_top {
  height: 160px;
  width: 100%;
  background-image: url(../../assets/头部底图.png);
  background-size: 100% 100%;
}
.logintop_min {
  width: 1200px;
  margin: auto;
}
.min_top {
  height: 160px;
  line-height: 160px;
  display: flex;
}
.top_img1 {
  display: flex;
  align-items: center;
}
.top_img2 {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
}
</style>